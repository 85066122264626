















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import SessionResponsesTab from '@/components/SessionResponsesTab.vue';
import QuestionGraphTab from '@/components/QuestionGraphTab.vue';
import OverviewTab from '@/components/OverviewTab.vue';
import TopicsTab from '@/components/TopicsTab.vue';
import DriversTab from '@/components/DriversTab.vue';
import EstimatesTab from '@/components/EstimatesTab.vue';
import OpenEndedQuestionResponseTab from '@/components/OpenEndedQuestionResponseTab.vue';
import NominalQuestionResponseTab from '@/components/NominalQuestionResponseTab.vue';
import NominalQuestionGraphTab from '@/components/NominalQuestionGraphTab.vue';
import {
  dispatchGetQuestionResponses,
  dispatchGetPublicSessionData,
} from '@/store/sessionData/actions';

import { apiUrl } from '@/env';
import store from '@/store';
import {
  readQuestionResponses,
  readSessionData,
  readQuestionData,
  readQuestionTopics,
} from '@/store/sessionData/getters';

import { IEstimate, IScenarioData } from '@/interfaces';
import { leadingScenarioData } from '@/components/utils';

@Component({
  components: {
    'question-graph-tab': QuestionGraphTab,
    'responses-tab': SessionResponsesTab,
    'topics-tab': TopicsTab,
    'drivers-tab': DriversTab,
    'overview-tab': OverviewTab,
    'estimates-tab': EstimatesTab,
    'open-ended-question-response-tab': OpenEndedQuestionResponseTab,
    'nominal-question-response-tab': NominalQuestionResponseTab,
    'nominal-question-graph-tab': NominalQuestionGraphTab,
  },
})
export default class PublicStudy extends Vue {

  public tab = [];
  public tagNested = [];
  public loading = true;
  public timer = 0;
  public singleImageDialog = false;
  public imageShown = {
    id: '',
    name: '',
    caption: '',
    url: '',
  };

  public questionHasImages(question) {
    return question.images.length > 0;
  }

  public openSignup() {
    window.open(`${apiUrl}/signup`);
  }

  public beforeDestroy() {
    clearInterval(this.timer);
  }

  public topicsDataLoaded(question_id) {
    return this.questionTopics(question_id);
  }

  public questionResponseCount(questionId: string) {
    const responses = readQuestionResponses(this.$store)(questionId);
    return responses ? responses.participants_count : 0;
  }

  public iframeHeightNotify() {
    const msg = {
      height: (this.$refs['res-container'] as HTMLBaseElement)?.scrollHeight,
      id: this.$route.params.id,
    };
    if (msg.height !== 0) {
      window.parent.postMessage(msg, '*');
    }
  }

  public onShowImageDialog(image) {
    this.imageShown = {
      id: image.id,
      name: image.name,
      caption: image.caption,
      url: image.url,
    };
    this.singleImageDialog = true;
  }  
  
  public onCloseImageDialog() {
    this.singleImageDialog = false;
  }

  get sessionData() {
    return readSessionData(this.$store);
  }

  public async mounted() {
    await dispatchGetPublicSessionData(store, { id: this.$route.params.id });
    this.timer = setInterval(this.iframeHeightNotify, 300);
    this.loading = false;
  }

  public questionData(questionId: string) {
    return readQuestionData(this.$store)(questionId);
  }

  public questionTopics(questionId: string) {
    return readQuestionTopics(this.$store)(questionId);
  }
  
  public async responseTabClicked(question_index: number) {
    await dispatchGetQuestionResponses(store, {questionId: this.sessionData.questions_with_data[question_index].id, pageLength: 5, pageNumber: 1, search: ''});
  }

  public getEstimate(item) {
    let estimates: IEstimate[];
    if (Array.isArray(item.data)) {
      estimates = leadingScenarioData(item.data as IScenarioData[]).estimates;
    }
    else {
      estimates = item.data.estimates;
    }
    return (estimates[estimates.length - 1].estimate * 100).toFixed(0);
  }

  public leadingContract(item) {
    if (Array.isArray(item.data)) {
      return leadingScenarioData(item.data as IScenarioData[]).contract.text;
    }
  }

}
